<!--
 * @Author: xr
 * @Date: 2022-02-17 14:35:20
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 10:58:34
 * @FilePath: \postStation-office\src\views\newsDetail.vue
-->
<template >
  <div class="main_box sub-component-background-color">
    <div class="new-border">
      <div class="new-title text-wrap-ellipsis">{{news.newsTopic || news.title || news.industryTopic}}</div>
      <center>
        <span> 来源：{{news.createBy}} </span>
        <span style="margin-left:10px;"> 时间：{{news.createTime?news.createTime.substring(0,10):news.createTime}} </span>
      </center>
      <article>
        <div class="content" v-html="news.newsContent || news.content || news.investmentPolicyContent || news.industryContent"/>
        <a-row type="flex">
          <a v-show="news.file" :href="news.file" style="color:#0967C1;">附件下载</a>
        </a-row>
      </article>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewsDetail',
  data() {
    return {
      news: {}
    }
  },
  activated() {
    // 刷新
    if (JSON.stringify(this.$route.params) !== '{}') {
      this.news = this.$route.params
      sessionStorage.setItem('newsItem', JSON.stringify(this.$route.params))
    } else {
      this.news = JSON.parse(sessionStorage.getItem('newsItem'))
    }
  }

}
</script>
<style lang="scss" scoped>
.new-border{
  border: 1px solid #bbdbff;
  border-radius: 8px;
  font-size: 14px;
  margin: 10px auto 20px auto;
  .new-title{
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    padding: 15px 50px 10px 50px;
    vertical-align: middle;
    font-family: "微软雅黑";
    color: #0967c1;
    font-weight: bold;
  }
  center{
    line-height: 30px;
    vertical-align: middle;
    color: #757575;
    font-size: 12px;
  }
  article{
    font-size: 14px;
    line-height: 25px;
    margin: 0 40px;
    border-top: 1px solid #94b6d7;
    padding: 15px 0px;
    .content{
      min-height: 50vh;
      text-indent:2em;
    }
    .file{
      display: block;
      color: #0967c1;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
